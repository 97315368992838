import { LocalScheme } from '@nuxtjs/auth-next';
export default class LocalClaimsScheme extends LocalScheme {
    constructor($auth, options, ...defaults) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { CONFIG } = require('@shared/environment/environment');
        const { apiUrl } = CONFIG;
        const loginPath = '/auth/jwt/create';
        const logoutPath = '/auth/jwt/logout';
        const userPath = '/auth/me';
        super($auth, {
            name: options.name,
            token: {
                property: 'access',
                name: 'API-Authorization',
            },
            user: {
                property: false,
            },
            endpoints: {
                login: {
                    url: new URL(loginPath, apiUrl).href,
                    method: 'POST',
                },
                logout: {
                    url: new URL(logoutPath, apiUrl).href,
                    method: 'POST',
                },
                user: {
                    url: new URL(userPath, apiUrl).href,
                    method: 'GET',
                },
            },
        }, ...defaults);
    }
    async fetchUser() {
        if (!this.check(false).valid || !this.options.endpoints.user) {
            return;
        }
        const { data } = await this.$auth.requestWith(this.name, this.options.endpoints.user);
        this.$auth.setUser(data);
        await this.$auth.ctx.store.dispatch('settings/fetchMenuEntries', undefined, { root: true });
    }
}
