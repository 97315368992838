import * as Sentry from '@sentry/browser';
import { DAMAGES_VALUATIONS_FILTERS_MAPPING, DAMAGES_VALUATIONS_SORTING_MAPPING, DAMAGE_VALUATIONS_PER_PAGE, } from '@backoffice/types/store/damagesValuations';
import { fetchResourceCollection, newListFilters, newListSorting, SortDirection, } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
const newDamagesValuationsFilters = () => newListFilters();
const newDamagesValuationsSorting = () => newListSorting('createdAt', SortDirection.Desc);
export const name = 'damagesValuations';
export const namespaced = true;
export const state = () => ({
    valuations: [],
    total: 0,
    loading: false,
    page: 1,
    filters: newDamagesValuationsFilters(),
    sorting: newDamagesValuationsSorting(),
});
export const getters = {
    perPage() {
        return DAMAGE_VALUATIONS_PER_PAGE;
    },
    totalPages(state, getters) {
        return Math.ceil((state.total || 0) / getters.perPage);
    },
};
export const actions = {
    async loadValuations({ commit, state }) {
        try {
            commit('SET_LOADING', true);
            const result = await fetchResourceCollection(ApiResourceName.DamagesValuations, this.$axios, state.page, [state.filters, DAMAGES_VALUATIONS_FILTERS_MAPPING], [state.sorting, DAMAGES_VALUATIONS_SORTING_MAPPING]);
            commit('SET_VALUATIONS', result.data);
            commit('SET_TOTAL', result.total);
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_VALUATIONS', []);
            commit('SET_TOTAL', 0);
        }
        finally {
            commit('SET_LOADING', false);
        }
    },
    async changePage({ commit, dispatch }, page) {
        commit('SET_PAGE', page);
        await dispatch('loadValuations');
    },
    async filter({ commit, dispatch }, filters) {
        commit('SET_FILTERS', filters);
        await dispatch('loadValuations');
    },
    async sort({ commit, dispatch }, sorting) {
        commit('SET_SORTING', sorting);
        await dispatch('loadValuations');
    },
};
export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_VALUATIONS(state, valuations) {
        state.valuations = [...valuations];
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_FILTERS(state, filters) {
        state.filters = { ...filters };
    },
    SET_SORTING(state, sorting) {
        state.sorting = { ...sorting };
    },
};
