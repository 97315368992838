export const USERS_PER_PAGE = 30;
export const USERS_FILTERS_MAPPING = {
    lastname: 'lastname',
    firstname: 'firstname',
    email: 'email',
};
export const USERS_SORTING_MAPPING = {
    lastname: 'order[lastname]',
    firstname: 'order[firstname]',
    email: 'order[email]',
};
