import { locale } from 'moment';
locale('fr', {
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    months: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
    ],
    longDateFormat: {
        LT: 'H[h]mm',
        LTS: 'H:mm:ss',
        L: 'DD/MM/YYYY',
        l: 'D/M/YYYY',
        LL: 'D MMMM YYYY',
        ll: 'D MMM YYYY',
        LLL: 'LL [à] LT',
        lll: 'll [à] LT',
        LLLL: 'dddd LL [à] LT',
        llll: 'ddd ll [à] LT',
    },
    calendar: {
        lastDay: '[Hier à] LT',
        sameDay: "[Aujourd'hui à] LT",
        nextDay: '[Demain à] LT',
        lastWeek: 'dddd [dernier à] LT',
        nextWeek: 'dddd [à] LT',
        sameElse: 'L',
    },
});
