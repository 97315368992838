import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
function mergePayloadCustomizer(_object, srcValue) {
    if (Array.isArray(srcValue)) {
        return srcValue;
    }
}
export function mergePayload(object, other) {
    const objectCloned = merge({}, object);
    const otherCloned = merge({}, other);
    return mergeWith(objectCloned, otherCloned, mergePayloadCustomizer);
}
