import { JSONEditor } from '@json-editor/json-editor';
import isArrayLike from 'lodash/isArrayLike';
class ClaimsTheme extends JSONEditor.defaults.themes.html {
    getHeader(text, pathDepth) {
        const el = document.createElement('h3');
        if (typeof text === 'string') {
            el.textContent = text;
        }
        else {
            el.appendChild(text);
        }
        if (pathDepth <= 1) {
            el.classList.add('subtitle');
        }
        else {
            el.classList.add('label', 'is-small');
        }
        return el;
    }
    getContainer() {
        const el = document.createElement('div');
        el.classList.add('block');
        return el;
    }
    getIndentedPanel() {
        const el = document.createElement('div');
        el.classList.add('block');
        return el;
    }
    getButton(text, icon, title) {
        const el = document.createElement('button');
        el.type = 'button';
        el.classList.add('button', 'is-primary', 'is-small');
        this.setButtonText(el, text, icon, title);
        return el;
    }
    getSelectInput(options, multiple) {
        const el = document.createElement('div');
        const select = document.createElement('select');
        el.classList.add('select', 'is-small');
        el.appendChild(select);
        if (options)
            this.setSelectOptions(select, options);
        if (multiple) {
            select.setAttribute('multiple', '');
            el.classList.add('is-multiple');
        }
        Object.defineProperty(el, 'value', {
            get() {
                return select.value;
            },
            set(v) {
                select.value = v;
            },
        });
        return el;
    }
    setSelectOptions(select, options, titles = []) {
        let el;
        if (select instanceof HTMLSelectElement)
            el = select;
        else
            el = select.firstChild;
        el.innerHTML = '';
        for (let i = 0; i < options.length; i++) {
            const option = document.createElement('option');
            option.setAttribute('value', options[i]);
            option.textContent = titles[i] || options[i];
            el.appendChild(option);
        }
    }
    getSwitcher(options) {
        const switcher = this.getSelectInput(options, false);
        return switcher;
    }
    getFormInputField(type) {
        const el = document.createElement('input');
        el.setAttribute('type', type);
        el.classList.add('input', 'is-small');
        return el;
    }
    getFormInputLabel(text) {
        const el = document.createElement('label');
        el.classList.add('label', 'is-small');
        el.appendChild(document.createTextNode(text));
        return el;
    }
    getFormControl(label, input, description, infoText, formName) {
        const el = document.createElement('div');
        el.classList.add('field');
        if (label) {
            el.appendChild(label);
            if (formName)
                label.setAttribute('for', formName);
        }
        if (formName) {
            input.setAttribute('id', formName);
        }
        if (input.type === 'checkbox' && label) {
            label.classList.add('checkbox');
            label.insertBefore(input, label.firstChild);
            if (infoText)
                label.appendChild(infoText);
        }
        else if (input.type === 'radio' && label) {
            label.classList.add('radio');
            label.insertBefore(input, label.firstChild);
            if (infoText)
                label.appendChild(infoText);
        }
        else {
            if (infoText && label)
                label.appendChild(infoText);
            const control = document.createElement('div');
            control.classList.add('control');
            control.appendChild(input);
            el.appendChild(control);
        }
        if (description)
            el.appendChild(description);
        return el;
    }
    getDescription(text) {
        const el = document.createElement('p');
        el.textContent = this.cleanText(text);
        return el;
    }
    getFormInputDescription(text) {
        const el = this.getDescription(text);
        el.classList.add('help');
        return el;
    }
    getTabHolder(propertyName) {
        const el = document.createElement('div');
        const tabsContainer = document.createElement('div');
        const tabsList = document.createElement('ul');
        tabsContainer.classList.add('tabs', 'is-toggle', 'is-small');
        tabsContainer.appendChild(tabsList);
        const tabsContent = document.createElement('div');
        tabsContent.classList.add('mt-4');
        if (propertyName) {
            tabsContent.setAttribute('id', propertyName);
        }
        el.appendChild(tabsContainer);
        el.appendChild(tabsContent);
        return el;
    }
    getTopTabHolder(propertyName) {
        return this.getTabHolder(propertyName);
    }
    insertBasicTopTab(tab, newTabsHolder) {
        newTabsHolder.firstChild.firstChild.insertBefore(tab, newTabsHolder.firstChild.firstChild.firstChild);
    }
    getTab(span, tabId) {
        const el = document.createElement('li');
        const a = document.createElement('a');
        a.appendChild(span);
        el.appendChild(a);
        el.setAttribute('id', tabId);
        return el;
    }
    getTopTab(span, tabId) {
        return this.getTab(span, tabId);
    }
    getTabContentHolder(tabHolder) {
        return tabHolder.children[1];
    }
    getTopTabContentHolder(tabHolder) {
        return this.getTabContentHolder(tabHolder);
    }
    markTabActive(row) {
        row.tab.classList.add('is-active');
        if (typeof row.rowPane !== 'undefined') {
            row.rowPane.classList.remove('is-hidden');
        }
        else {
            row.container.classList.remove('is-hidden');
        }
    }
    markTabInactive(row) {
        row.tab.classList.remove('is-active');
        if (typeof row.rowPane !== 'undefined') {
            row.rowPane.classList.add('is-hidden');
        }
        else {
            row.container.classList.add('is-hidden');
        }
    }
    addTab(holder, tab) {
        holder.children[0].children[0].appendChild(tab);
    }
    addTopTab(holder, tab) {
        holder.children[0].children[0].appendChild(tab);
    }
    getFirstTab(holder) {
        var _a, _b;
        return (_b = (_a = holder.firstChild) === null || _a === void 0 ? void 0 : _a.firstChild) === null || _b === void 0 ? void 0 : _b.firstChild;
    }
}
class ClaimsEnumEditor extends JSONEditor.defaults.editors.enum {
    build() {
        super.build();
        const container = this.container;
        const switcher = this.switcher;
        const el = document.createElement('div');
        container.insertBefore(el, switcher);
        el.classList.add('block');
        el.appendChild(switcher);
    }
    getHTML(el) {
        const each = (obj, callback) => {
            if (isArrayLike(obj)) {
                Array.from(obj).forEach((e, i) => callback(i, e));
            }
            else {
                Object.entries(obj).forEach(([key, value]) => callback(key, value));
            }
        };
        if (el === null) {
            return '<code>null</code>';
        }
        else if (typeof el === 'object') {
            let ret = '<table class="table is-fullwidth is-bordered is-narrow"><thead></thead><tbody>';
            each(el, (i, child) => {
                ret += `<tr><td scope="row" class="is-narrow">${i}</td><td>${this.getHTML(child)}</td></tr>`;
            });
            return ret + '</tbody></table>';
        }
        else if (typeof el === 'boolean') {
            return `<code>${el ? 'true' : 'false'}</code>`;
        }
        else if (typeof el === 'string') {
            return `<code>"${el
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')}"</code>`;
        }
        return `<code>${el}</code>`;
    }
}
export default (function (_ctx, inject) {
    JSONEditor.defaults.editors.enum = ClaimsEnumEditor;
    JSONEditor.defaults.themes.claims = ClaimsTheme;
    JSONEditor.defaults.theme = 'claims';
    const jsonEditor = {
        create: (elem, options) => new JSONEditor(elem, options),
    };
    inject('jsonEditor', jsonEditor);
});
