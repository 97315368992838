import Vue from 'vue';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { uuidFromIri } from '@shared/utils/iri';
export const name = 'damagesValuation';
export const namespaced = true;
function newState() {
    return {
        id: null,
        valuation: null,
        departments: [],
        productLines: [],
        error: null,
        isLoading: false,
        isSaving: false,
    };
}
export const state = () => newState();
export const getters = {};
function fetchDamagesValuation(id, $axios) {
    return $axios.$get(`${apiResourcePath(ApiResourceName.DamagesValuations)}/${uuidFromIri(id)}`);
}
function putDamagesValuation($axios, state, payload) {
    var _a, _b, _c;
    const id = (_b = (_a = state.valuation) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
    if (!id) {
        throw new Error('Cannot save damagesValuation without id');
    }
    return $axios.$put(`${apiResourcePath(ApiResourceName.DamagesValuations)}/${uuidFromIri(id)}`, { ...state.valuation, ...payload, creator: (_c = state.valuation) === null || _c === void 0 ? void 0 : _c.creator.id });
}
export const actions = {
    async fetchDepartments({ commit }) {
        const departments = await this.$axios
            .$get(apiResourcePath(ApiResourceName.Departments))
            .then((response) => response['hydra:member']);
        commit('SET_DEPARTMENTS', departments);
    },
    async fetchProductLines({ commit }) {
        const productLines = await this.$axios
            .$get(apiResourcePath(ApiResourceName.ProductLines))
            .then((response) => response['hydra:member']);
        commit('SET_PRODUCT_LINES', productLines);
    },
    async selectDamagesValuation({ commit }, id) {
        commit('SELECT_DAMAGES_VALUATION', id);
        try {
            const valuation = await fetchDamagesValuation(id, this.$axios);
            commit('SET_VALUATION', valuation);
        }
        catch (err) {
            return commit('SET_ERROR', 'Impossible de récupérer les informations de cette déclaration');
        }
    },
    update({ commit }, payload) {
        commit('UPDATE', payload);
    },
    async save({ commit, state }, payload) {
        var _a;
        if (!((_a = state.valuation) === null || _a === void 0 ? void 0 : _a.id)) {
            return;
        }
        commit('SET_IS_SAVING', true);
        const damagesValuation = await putDamagesValuation(this.$axios, state, payload);
        commit('SET_VALUATION', damagesValuation);
        commit('SET_IS_SAVING', false);
    },
};
export const mutations = {
    RESET(state) {
        Object.assign(state, newState());
    },
    SET_DEPARTMENTS(state, departments) {
        state.departments = departments;
    },
    SET_PRODUCT_LINES(state, productLines) {
        state.productLines = productLines;
    },
    SET_VALUATION(state, valuation) {
        state.valuation = valuation;
        state.isLoading = false;
    },
    SELECT_DAMAGES_VALUATION(state, id) {
        state.id = id;
        state.valuation = null;
        state.isLoading = true;
        state.isSaving = false;
        state.error = null;
    },
    SET_IS_SAVING(state, saving) {
        state.isSaving = saving;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_IS_LOADING(state, loading) {
        state.isLoading = loading;
    },
    UPDATE(state, payload) {
        if (!state.valuation) {
            return;
        }
        for (const key in payload) {
            Vue.set(state.valuation, key, payload[key]);
        }
    },
};
