import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
export default (function ({ store, $config }) {
    if (!process.env.NUXT_ENV_SENTRY_RELEASE) {
        return;
    }
    const sentryOptions = {
        environment: process.env.NODE_ENV,
        release: process.env.NUXT_ENV_SENTRY_RELEASE,
        dsn: 'https://6d80619d271a40d6bce392d239b2cfc8@sentry.io/3049021',
        integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    };
    Sentry.init(sentryOptions);
    Sentry.setTag('insurer', $config.insurerConfig.id);
    // Set user context
    store.watch(({ auth: { user } }) => {
        var _a, _b, _c, _d;
        if (user === null) {
            return null;
        }
        return {
            email: (_a = user.email) !== null && _a !== void 0 ? _a : undefined,
            username: `${(_b = user.firstname) !== null && _b !== void 0 ? _b : ''} ${(_c = user.lastname) !== null && _c !== void 0 ? _c : ''}`.trim() || undefined,
            roles: (_d = user.roles) !== null && _d !== void 0 ? _d : undefined,
        };
    }, (userInfo) => {
        Sentry.configureScope((scope) => {
            scope.setUser(userInfo);
        });
    }, { deep: true, immediate: true });
    // Set claim context
    store.watch(({ claim: { id } }) => {
        return {
            claim: id,
        };
    }, (claimInfo) => {
        Sentry.configureScope((scope) => {
            scope.setExtras(claimInfo);
        });
    }, { deep: true, immediate: true });
});
