const middleware = {}

middleware['departments'] = require('../../../src/backoffice/middleware/departments.ts')
middleware['departments'] = middleware['departments'].default || middleware['departments']

middleware['hasAccess'] = require('../../../src/backoffice/middleware/hasAccess.ts')
middleware['hasAccess'] = middleware['hasAccess'].default || middleware['hasAccess']

middleware['productLines'] = require('../../../src/backoffice/middleware/productLines.ts')
middleware['productLines'] = middleware['productLines'].default || middleware['productLines']

middleware['router'] = require('../../../src/backoffice/middleware/router.ts')
middleware['router'] = middleware['router'].default || middleware['router']

middleware['settings'] = require('../../../src/backoffice/middleware/settings.ts')
middleware['settings'] = middleware['settings'].default || middleware['settings']

export default middleware
