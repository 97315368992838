import Vue from 'vue'
import Buefy from 'buefy'

const options = JSON.parse(`{"defaultIconPack":"mdi","customIconPacks":{"claims-icon-buefy":{"sizes":{"default":"is-size-5","is-small":"is-size-5","is-medium":"is-size-3","is-large":"is-size-1"},"iconPrefix":"icon-"}}}`)

Vue.use(Buefy, options)

export default (ctx, inject) => {
  ctx.$buefy = Vue.prototype.$buefy
  inject('buefy', Vue.prototype.$buefy)
}
