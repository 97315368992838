import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e684ffb0 = () => interopDefault(import('../../../src/backoffice/pages/aide.vue' /* webpackChunkName: "pages/aide" */))
const _075bb664 = () => interopDefault(import('../../../src/backoffice/pages/chiffrages/index.vue' /* webpackChunkName: "pages/chiffrages/index" */))
const _52ed4e3c = () => interopDefault(import('../../../src/backoffice/pages/contrats/index.vue' /* webpackChunkName: "pages/contrats/index" */))
const _8f24183a = () => interopDefault(import('../../../src/backoffice/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4a9a893b = () => interopDefault(import('../../../src/backoffice/pages/declarations/index.vue' /* webpackChunkName: "pages/declarations/index" */))
const _09b7cdc6 = () => interopDefault(import('../../../src/backoffice/pages/document/index.vue' /* webpackChunkName: "pages/document/index" */))
const _7b5b6c46 = () => interopDefault(import('../../../src/backoffice/pages/informations.vue' /* webpackChunkName: "pages/informations" */))
const _fbd4d20c = () => interopDefault(import('../../../src/backoffice/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ed3dad0c = () => interopDefault(import('../../../src/backoffice/pages/rendez-vous/index.vue' /* webpackChunkName: "pages/rendez-vous/index" */))
const _07d7db92 = () => interopDefault(import('../../../src/backoffice/pages/utilisateurs/index.vue' /* webpackChunkName: "pages/utilisateurs/index" */))
const _7f533c97 = () => interopDefault(import('../../../src/backoffice/pages/configuration/menu.vue' /* webpackChunkName: "pages/configuration/menu" */))
const _a81cb218 = () => interopDefault(import('../../../src/backoffice/pages/configuration/templates/index.vue' /* webpackChunkName: "pages/configuration/templates/index" */))
const _eb594eae = () => interopDefault(import('../../../src/backoffice/pages/declarations/nouvelle.vue' /* webpackChunkName: "pages/declarations/nouvelle" */))
const _69b9238c = () => interopDefault(import('../../../src/backoffice/pages/services/produits.vue' /* webpackChunkName: "pages/services/produits" */))
const _76523a37 = () => interopDefault(import('../../../src/backoffice/pages/utilisateurs/nouveau.vue' /* webpackChunkName: "pages/utilisateurs/nouveau" */))
const _2bea653a = () => interopDefault(import('../../../src/backoffice/pages/declarations/form-declaration/form-declaration-departement.vue' /* webpackChunkName: "pages/declarations/form-declaration/form-declaration-departement" */))
const _3cddfb40 = () => interopDefault(import('../../../src/backoffice/pages/declarations/form-declaration/form-declaration-generique.vue' /* webpackChunkName: "pages/declarations/form-declaration/form-declaration-generique" */))
const _61e4d65c = () => interopDefault(import('../../../src/backoffice/pages/configuration/templates/_id.vue' /* webpackChunkName: "pages/configuration/templates/_id" */))
const _374a18e3 = () => interopDefault(import('../../../src/backoffice/pages/chiffrages/_valuation/index.vue' /* webpackChunkName: "pages/chiffrages/_valuation/index" */))
const _b7c0c33a = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id.vue' /* webpackChunkName: "pages/declarations/_id" */))
const _1a071d5c = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/appel.vue' /* webpackChunkName: "pages/declarations/_id/appel" */))
const _6c0e5207 = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/contrat.vue' /* webpackChunkName: "pages/declarations/_id/contrat" */))
const _45d12a4e = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/declaration.vue' /* webpackChunkName: "pages/declarations/_id/declaration" */))
const _5cc9729b = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/dommages.vue' /* webpackChunkName: "pages/declarations/_id/dommages" */))
const _99f9f0f2 = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/frais-de-gestion.vue' /* webpackChunkName: "pages/declarations/_id/frais-de-gestion" */))
const _67b27a1a = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/ged.vue' /* webpackChunkName: "pages/declarations/_id/ged" */))
const _51c44278 = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/indemnisation.vue' /* webpackChunkName: "pages/declarations/_id/indemnisation" */))
const _55690d50 = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/priming.vue' /* webpackChunkName: "pages/declarations/_id/priming" */))
const _da4e71ae = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/provision.vue' /* webpackChunkName: "pages/declarations/_id/provision" */))
const _a3b81e22 = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/ren.vue' /* webpackChunkName: "pages/declarations/_id/ren" */))
const _27c3ec17 = () => interopDefault(import('../../../src/backoffice/pages/declarations/_id/synthese.vue' /* webpackChunkName: "pages/declarations/_id/synthese" */))
const _5d564506 = () => interopDefault(import('../../../src/backoffice/pages/utilisateurs/_id/index.vue' /* webpackChunkName: "pages/utilisateurs/_id/index" */))
const _789e6748 = () => interopDefault(import('../../../src/backoffice/pages/utilisateurs/_id/nouveau-contrat.vue' /* webpackChunkName: "pages/utilisateurs/_id/nouveau-contrat" */))
const _ae087000 = () => interopDefault(import('../../../src/backoffice/pages/chiffrages/_valuation/_damage/_key.vue' /* webpackChunkName: "pages/chiffrages/_valuation/_damage/_key" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aide",
    component: _e684ffb0,
    name: "aide___fr"
  }, {
    path: "/chiffrages",
    component: _075bb664,
    name: "chiffrages___fr"
  }, {
    path: "/contrats",
    component: _52ed4e3c,
    name: "contrats___fr"
  }, {
    path: "/de",
    component: _8f24183a,
    name: "index___de"
  }, {
    path: "/declarations",
    component: _4a9a893b,
    name: "declarations___fr"
  }, {
    path: "/document",
    component: _09b7cdc6,
    name: "document___fr"
  }, {
    path: "/en",
    component: _8f24183a,
    name: "index___en"
  }, {
    path: "/informations",
    component: _7b5b6c46,
    name: "informations___fr"
  }, {
    path: "/it",
    component: _8f24183a,
    name: "index___it"
  }, {
    path: "/login",
    component: _fbd4d20c,
    name: "login___fr"
  }, {
    path: "/rendez-vous",
    component: _ed3dad0c,
    name: "rendez-vous___fr"
  }, {
    path: "/utilisateurs",
    component: _07d7db92,
    name: "utilisateurs___fr"
  }, {
    path: "/configuration/menu",
    component: _7f533c97,
    name: "configuration-menu___fr"
  }, {
    path: "/configuration/templates",
    component: _a81cb218,
    name: "configuration-templates___fr"
  }, {
    path: "/de/aide",
    component: _e684ffb0,
    name: "aide___de"
  }, {
    path: "/de/chiffrages",
    component: _075bb664,
    name: "chiffrages___de"
  }, {
    path: "/de/contrats",
    component: _52ed4e3c,
    name: "contrats___de"
  }, {
    path: "/de/declarations",
    component: _4a9a893b,
    name: "declarations___de"
  }, {
    path: "/de/document",
    component: _09b7cdc6,
    name: "document___de"
  }, {
    path: "/de/informations",
    component: _7b5b6c46,
    name: "informations___de"
  }, {
    path: "/de/login",
    component: _fbd4d20c,
    name: "login___de"
  }, {
    path: "/de/rendez-vous",
    component: _ed3dad0c,
    name: "rendez-vous___de"
  }, {
    path: "/de/utilisateurs",
    component: _07d7db92,
    name: "utilisateurs___de"
  }, {
    path: "/declarations/nouvelle",
    component: _eb594eae,
    name: "declarations-nouvelle___fr"
  }, {
    path: "/en/aide",
    component: _e684ffb0,
    name: "aide___en"
  }, {
    path: "/en/chiffrages",
    component: _075bb664,
    name: "chiffrages___en"
  }, {
    path: "/en/claims",
    component: _4a9a893b,
    name: "declarations___en"
  }, {
    path: "/en/contrats",
    component: _52ed4e3c,
    name: "contrats___en"
  }, {
    path: "/en/document",
    component: _09b7cdc6,
    name: "document___en"
  }, {
    path: "/en/informations",
    component: _7b5b6c46,
    name: "informations___en"
  }, {
    path: "/en/login",
    component: _fbd4d20c,
    name: "login___en"
  }, {
    path: "/en/rendez-vous",
    component: _ed3dad0c,
    name: "rendez-vous___en"
  }, {
    path: "/en/utilisateurs",
    component: _07d7db92,
    name: "utilisateurs___en"
  }, {
    path: "/it/aide",
    component: _e684ffb0,
    name: "aide___it"
  }, {
    path: "/it/chiffrages",
    component: _075bb664,
    name: "chiffrages___it"
  }, {
    path: "/it/contrats",
    component: _52ed4e3c,
    name: "contrats___it"
  }, {
    path: "/it/declarations",
    component: _4a9a893b,
    name: "declarations___it"
  }, {
    path: "/it/document",
    component: _09b7cdc6,
    name: "document___it"
  }, {
    path: "/it/informations",
    component: _7b5b6c46,
    name: "informations___it"
  }, {
    path: "/it/login",
    component: _fbd4d20c,
    name: "login___it"
  }, {
    path: "/it/rendez-vous",
    component: _ed3dad0c,
    name: "rendez-vous___it"
  }, {
    path: "/it/utilisateurs",
    component: _07d7db92,
    name: "utilisateurs___it"
  }, {
    path: "/services/produits",
    component: _69b9238c,
    name: "services-produits___fr"
  }, {
    path: "/utilisateurs/nouveau",
    component: _76523a37,
    name: "utilisateurs-nouveau___fr"
  }, {
    path: "/de/configuration/menu",
    component: _7f533c97,
    name: "configuration-menu___de"
  }, {
    path: "/de/configuration/templates",
    component: _a81cb218,
    name: "configuration-templates___de"
  }, {
    path: "/de/declarations/nouvelle",
    component: _eb594eae,
    name: "declarations-nouvelle___de"
  }, {
    path: "/de/services/produits",
    component: _69b9238c,
    name: "services-produits___de"
  }, {
    path: "/de/utilisateurs/nouveau",
    component: _76523a37,
    name: "utilisateurs-nouveau___de"
  }, {
    path: "/declarations/form-declaration/form-declaration-departement",
    component: _2bea653a,
    name: "declarations-form-declaration-form-declaration-departement___fr"
  }, {
    path: "/declarations/form-declaration/form-declaration-generique",
    component: _3cddfb40,
    name: "declarations-form-declaration-form-declaration-generique___fr"
  }, {
    path: "/en/configuration/menu",
    component: _7f533c97,
    name: "configuration-menu___en"
  }, {
    path: "/en/configuration/templates",
    component: _a81cb218,
    name: "configuration-templates___en"
  }, {
    path: "/en/declarations/nouvelle",
    component: _eb594eae,
    name: "declarations-nouvelle___en"
  }, {
    path: "/en/services/produits",
    component: _69b9238c,
    name: "services-produits___en"
  }, {
    path: "/en/utilisateurs/nouveau",
    component: _76523a37,
    name: "utilisateurs-nouveau___en"
  }, {
    path: "/it/configuration/menu",
    component: _7f533c97,
    name: "configuration-menu___it"
  }, {
    path: "/it/configuration/templates",
    component: _a81cb218,
    name: "configuration-templates___it"
  }, {
    path: "/it/declarations/nouvelle",
    component: _eb594eae,
    name: "declarations-nouvelle___it"
  }, {
    path: "/it/services/produits",
    component: _69b9238c,
    name: "services-produits___it"
  }, {
    path: "/it/utilisateurs/nouveau",
    component: _76523a37,
    name: "utilisateurs-nouveau___it"
  }, {
    path: "/de/declarations/form-declaration/form-declaration-departement",
    component: _2bea653a,
    name: "declarations-form-declaration-form-declaration-departement___de"
  }, {
    path: "/de/declarations/form-declaration/form-declaration-generique",
    component: _3cddfb40,
    name: "declarations-form-declaration-form-declaration-generique___de"
  }, {
    path: "/en/declarations/form-declaration/form-declaration-departement",
    component: _2bea653a,
    name: "declarations-form-declaration-form-declaration-departement___en"
  }, {
    path: "/en/declarations/form-declaration/form-declaration-generique",
    component: _3cddfb40,
    name: "declarations-form-declaration-form-declaration-generique___en"
  }, {
    path: "/it/declarations/form-declaration/form-declaration-departement",
    component: _2bea653a,
    name: "declarations-form-declaration-form-declaration-departement___it"
  }, {
    path: "/it/declarations/form-declaration/form-declaration-generique",
    component: _3cddfb40,
    name: "declarations-form-declaration-form-declaration-generique___it"
  }, {
    path: "/de/configuration/templates/:id",
    component: _61e4d65c,
    name: "configuration-templates-id___de"
  }, {
    path: "/en/configuration/templates/:id",
    component: _61e4d65c,
    name: "configuration-templates-id___en"
  }, {
    path: "/it/configuration/templates/:id",
    component: _61e4d65c,
    name: "configuration-templates-id___it"
  }, {
    path: "/configuration/templates/:id",
    component: _61e4d65c,
    name: "configuration-templates-id___fr"
  }, {
    path: "/de/chiffrages/:valuation",
    component: _374a18e3,
    name: "chiffrages-valuation___de"
  }, {
    path: "/de/declarations/:id",
    component: _b7c0c33a,
    name: "declarations-id___de",
    children: [{
      path: "appel",
      component: _1a071d5c,
      name: "declarations-id-appel___de"
    }, {
      path: "contrat",
      component: _6c0e5207,
      name: "declarations-id-contrat___de"
    }, {
      path: "declaration",
      component: _45d12a4e,
      name: "declarations-id-declaration___de"
    }, {
      path: "dommages",
      component: _5cc9729b,
      name: "declarations-id-dommages___de"
    }, {
      path: "frais-de-gestion",
      component: _99f9f0f2,
      name: "declarations-id-frais-de-gestion___de"
    }, {
      path: "ged",
      component: _67b27a1a,
      name: "declarations-id-ged___de"
    }, {
      path: "indemnisation",
      component: _51c44278,
      name: "declarations-id-indemnisation___de"
    }, {
      path: "priming",
      component: _55690d50,
      name: "declarations-id-priming___de"
    }, {
      path: "provision",
      component: _da4e71ae,
      name: "declarations-id-provision___de"
    }, {
      path: "ren",
      component: _a3b81e22,
      name: "declarations-id-ren___de"
    }, {
      path: "synthese",
      component: _27c3ec17,
      name: "declarations-id-synthese___de"
    }]
  }, {
    path: "/de/utilisateurs/:id",
    component: _5d564506,
    name: "utilisateurs-id___de"
  }, {
    path: "/en/chiffrages/:valuation",
    component: _374a18e3,
    name: "chiffrages-valuation___en"
  }, {
    path: "/en/claims/:id",
    component: _b7c0c33a,
    name: "declarations-id___en",
    children: [{
      path: "/en/claims/:id/call",
      component: _1a071d5c,
      name: "declarations-id-appel___en"
    }, {
      path: "/en/claims/:id/claim",
      component: _45d12a4e,
      name: "declarations-id-declaration___en"
    }, {
      path: "/en/claims/:id/compensation",
      component: _51c44278,
      name: "declarations-id-indemnisation___en"
    }, {
      path: "/en/claims/:id/contract",
      component: _6c0e5207,
      name: "declarations-id-contrat___en"
    }, {
      path: "/en/claims/:id/damages",
      component: _5cc9729b,
      name: "declarations-id-dommages___en"
    }, {
      path: "/en/claims/:id/dms",
      component: _67b27a1a,
      name: "declarations-id-ged___en"
    }, {
      path: "/en/claims/:id/management-fees",
      component: _99f9f0f2,
      name: "declarations-id-frais-de-gestion___en"
    }, {
      path: "/en/claims/:id/priming",
      component: _55690d50,
      name: "declarations-id-priming___en"
    }, {
      path: "/en/claims/:id/provision",
      component: _da4e71ae,
      name: "declarations-id-provision___en"
    }, {
      path: "/en/claims/:id/rik",
      component: _a3b81e22,
      name: "declarations-id-ren___en"
    }, {
      path: "/en/claims/:id/synthesis",
      component: _27c3ec17,
      name: "declarations-id-synthese___en"
    }]
  }, {
    path: "/en/utilisateurs/:id",
    component: _5d564506,
    name: "utilisateurs-id___en"
  }, {
    path: "/it/chiffrages/:valuation",
    component: _374a18e3,
    name: "chiffrages-valuation___it"
  }, {
    path: "/it/declarations/:id",
    component: _b7c0c33a,
    name: "declarations-id___it",
    children: [{
      path: "appel",
      component: _1a071d5c,
      name: "declarations-id-appel___it"
    }, {
      path: "contrat",
      component: _6c0e5207,
      name: "declarations-id-contrat___it"
    }, {
      path: "declaration",
      component: _45d12a4e,
      name: "declarations-id-declaration___it"
    }, {
      path: "dommages",
      component: _5cc9729b,
      name: "declarations-id-dommages___it"
    }, {
      path: "frais-de-gestion",
      component: _99f9f0f2,
      name: "declarations-id-frais-de-gestion___it"
    }, {
      path: "ged",
      component: _67b27a1a,
      name: "declarations-id-ged___it"
    }, {
      path: "indemnisation",
      component: _51c44278,
      name: "declarations-id-indemnisation___it"
    }, {
      path: "priming",
      component: _55690d50,
      name: "declarations-id-priming___it"
    }, {
      path: "provision",
      component: _da4e71ae,
      name: "declarations-id-provision___it"
    }, {
      path: "ren",
      component: _a3b81e22,
      name: "declarations-id-ren___it"
    }, {
      path: "synthese",
      component: _27c3ec17,
      name: "declarations-id-synthese___it"
    }]
  }, {
    path: "/it/utilisateurs/:id",
    component: _5d564506,
    name: "utilisateurs-id___it"
  }, {
    path: "/de/utilisateurs/:id/nouveau-contrat",
    component: _789e6748,
    name: "utilisateurs-id-nouveau-contrat___de"
  }, {
    path: "/en/utilisateurs/:id/nouveau-contrat",
    component: _789e6748,
    name: "utilisateurs-id-nouveau-contrat___en"
  }, {
    path: "/it/utilisateurs/:id/nouveau-contrat",
    component: _789e6748,
    name: "utilisateurs-id-nouveau-contrat___it"
  }, {
    path: "/de/chiffrages/:valuation/:damage/:key?",
    component: _ae087000,
    name: "chiffrages-valuation-damage-key___de"
  }, {
    path: "/en/chiffrages/:valuation/:damage/:key?",
    component: _ae087000,
    name: "chiffrages-valuation-damage-key___en"
  }, {
    path: "/it/chiffrages/:valuation/:damage/:key?",
    component: _ae087000,
    name: "chiffrages-valuation-damage-key___it"
  }, {
    path: "/chiffrages/:valuation",
    component: _374a18e3,
    name: "chiffrages-valuation___fr"
  }, {
    path: "/declarations/:id",
    component: _b7c0c33a,
    name: "declarations-id___fr",
    children: [{
      path: "appel",
      component: _1a071d5c,
      name: "declarations-id-appel___fr"
    }, {
      path: "contrat",
      component: _6c0e5207,
      name: "declarations-id-contrat___fr"
    }, {
      path: "declaration",
      component: _45d12a4e,
      name: "declarations-id-declaration___fr"
    }, {
      path: "dommages",
      component: _5cc9729b,
      name: "declarations-id-dommages___fr"
    }, {
      path: "frais-de-gestion",
      component: _99f9f0f2,
      name: "declarations-id-frais-de-gestion___fr"
    }, {
      path: "ged",
      component: _67b27a1a,
      name: "declarations-id-ged___fr"
    }, {
      path: "indemnisation",
      component: _51c44278,
      name: "declarations-id-indemnisation___fr"
    }, {
      path: "priming",
      component: _55690d50,
      name: "declarations-id-priming___fr"
    }, {
      path: "provision",
      component: _da4e71ae,
      name: "declarations-id-provision___fr"
    }, {
      path: "ren",
      component: _a3b81e22,
      name: "declarations-id-ren___fr"
    }, {
      path: "synthese",
      component: _27c3ec17,
      name: "declarations-id-synthese___fr"
    }]
  }, {
    path: "/utilisateurs/:id",
    component: _5d564506,
    name: "utilisateurs-id___fr"
  }, {
    path: "/utilisateurs/:id/nouveau-contrat",
    component: _789e6748,
    name: "utilisateurs-id-nouveau-contrat___fr"
  }, {
    path: "/chiffrages/:valuation/:damage/:key?",
    component: _ae087000,
    name: "chiffrages-valuation-damage-key___fr"
  }, {
    path: "/",
    component: _8f24183a,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
