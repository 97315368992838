import { SERIALIZATION_KEY_BACKOFFICE_LIST } from '@backoffice/constants/serializationKeys';
import { SERIALIZATION_QUERY_PARAM_KEY } from '@shared/constants/serializationKeys';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
export var SortDirection;
(function (SortDirection) {
    SortDirection["Asc"] = "asc";
    SortDirection["Desc"] = "desc";
    SortDirection["None"] = "none";
})(SortDirection || (SortDirection = {}));
export function newListFilters(object) {
    return { ...object };
}
export function newListSorting(by, direction = SortDirection.None) {
    return { by, direction };
}
export function filterToQueryParams(filter, mapping) {
    return Object.fromEntries(Object.entries(filter)
        .filter((entry) => typeof entry[1] !== 'undefined' && entry[1] !== '')
        .map(([key, value]) => [mapping[key], value]));
}
export function sortingToQueryParams(sorting, mapping) {
    if (!sorting.direction || !sorting.by || !(sorting.by in mapping)) {
        return {};
    }
    return {
        [mapping[sorting.by]]: sorting.direction,
    };
}
export async function fetchResourceCollection(name, axios, pageOrParams, filter, sorting, additionalParams) {
    const params = {
        [SERIALIZATION_QUERY_PARAM_KEY]: SERIALIZATION_KEY_BACKOFFICE_LIST,
        ...(typeof pageOrParams !== 'object'
            ? {
                ...(filter ? filterToQueryParams(filter[0], filter[1]) : {}),
                ...(sorting ? sortingToQueryParams(sorting[0], sorting[1]) : {}),
                page: pageOrParams !== null && pageOrParams !== void 0 ? pageOrParams : undefined,
            }
            : pageOrParams),
        ...additionalParams,
    };
    const res = await axios.$get(apiResourcePath(name), { params });
    return {
        total: res['hydra:totalItems'],
        data: res['hydra:member'],
    };
}
export async function fetchResource(name, id, axios, params) {
    const res = await axios.$get(`${apiResourcePath(name)}/${id}`, {
        params,
    });
    return { data: res };
}
