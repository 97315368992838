import moment from 'moment';
import Vue from 'vue';
import { uuidFromIri } from '@shared/utils/iri';
import { backofficeAppointmentConstraints } from '@shared/validator/constraints/appointmentConstraints';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, revealViolations, touch, untouch, validate, } from '@shared/validator/validator';
export const name = 'expertAppointment';
export const namespaced = true;
const newUserContact = () => ({
    email: null,
    phoneNumber: null,
    addressDetails: null,
});
export const state = () => {
    const payload = { userContact: newUserContact(), selectedTimeSlot: null };
    const validator = newValidator(payload);
    validate(validator, payload, backofficeAppointmentConstraints);
    return {
        isFindingAppointments: false,
        isConfirmingAppointment: false,
        appointmentFindError: null,
        appointmentFindResult: null,
        payload,
        validator,
    };
};
export const getters = {
    validation(state) {
        return new ValidationContext(state.validator);
    },
    selectedAppointment(state) {
        var _a, _b;
        if (!state.payload.selectedTimeSlot) {
            return null;
        }
        const appointments = (_a = state.appointmentFindResult) === null || _a === void 0 ? void 0 : _a.appointments;
        if (!appointments) {
            return null;
        }
        return (_b = appointments.timeslots.find(({ id }) => id === state.payload.selectedTimeSlot)) !== null && _b !== void 0 ? _b : null;
    },
    sortedAppointments(state) {
        if (!state.appointmentFindResult)
            return [];
        return Array.from(state.appointmentFindResult.appointments.timeslots
            .reduce((acc, appointment) => {
            var _a;
            const start = moment(appointment.startTime).startOf('day').valueOf();
            const appointments = (_a = acc.get(start)) !== null && _a !== void 0 ? _a : [];
            appointments.push(appointment);
            acc.set(start, appointments);
            return acc;
        }, new Map())
            .entries())
            .sort((a, b) => a[0] - b[0])
            .map(([date, appointments]) => {
            return [
                moment(date).format('ddd DD/MM'),
                appointments.map((appointment) => {
                    let startLabel = moment(appointment.startTime).format('H[h]mm');
                    let endLabel = moment(appointment.endTime).format('H[h]mm');
                    if (startLabel.endsWith('00'))
                        startLabel = startLabel.slice(0, -2);
                    if (endLabel.endsWith('00'))
                        endLabel = endLabel.slice(0, -2);
                    return {
                        id: appointment.id,
                        type: appointment.type,
                        label: `${startLabel} - ${endLabel}`,
                    };
                }),
            ];
        });
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updatePayload({ commit }, payload) {
        commit('UPDATE_APPOINTMENT_PAYLOAD', payload);
    },
    validatePayload({ commit }) {
        commit('VALIDATE_AND_REVEAL_PAYLOAD');
    },
    async fetchExpertAppointments({ commit, rootState }, appointmentType) {
        commit('RESET_STATE_WITHOUT_CONTACT');
        commit('SET_IS_FINDING_APPOINTMENTS', true);
        const claim = rootState.claim;
        if (claim === null || !claim.id) {
            throw new Error('No claim for expert appointment fetch');
        }
        try {
            const appointments = await this.$axios.$post(`/expert-appointment/find-appointment/${uuidFromIri(claim.id)}`, { appointmentType });
            commit('SET_APPOINTMENT_FIND_RESULT', appointments);
        }
        catch (err) {
            let message;
            if (err instanceof Error)
                message = err.message;
            else
                message = Object.prototype.toString.call(err);
            commit('SET_APPOINTMENT_FIND_FAILED', message);
        }
    },
    async confirmAppointment({ rootState, getters, commit, state: { appointmentFindResult, payload } }, forcedExpertise) {
        if (!rootState.claim.id || !appointmentFindResult) {
            return;
        }
        const timeSlot = getters.selectedAppointment;
        if (!timeSlot) {
            return;
        }
        const bookPayload = {
            sessionID: appointmentFindResult.appointments.sessionID,
            timeslot: getters.selectedAppointment,
            userContact: payload.userContact,
            forcedExpertise,
        };
        commit('SET_IS_CONFIRMING_APPOINTMENT', true);
        try {
            await this.$axios.$post(`/expert-appointment/book-timeslot/${uuidFromIri(rootState.claim.id)}`, bookPayload);
        }
        finally {
            commit('SET_IS_CONFIRMING_APPOINTMENT', false);
        }
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    RESET_STATE_WITHOUT_CONTACT(stateObject) {
        const userContact = stateObject.payload.userContact;
        Object.assign(stateObject, state());
        Object.assign(stateObject.payload.userContact, userContact);
    },
    SET_APPOINTMENT_FIND_RESULT(state, appointmentSearchResult) {
        state.appointmentFindResult = appointmentSearchResult;
        state.isFindingAppointments = false;
    },
    SET_APPOINTMENT_FIND_FAILED(state, error) {
        state.isFindingAppointments = false;
        state.appointmentFindError = error;
    },
    SET_IS_FINDING_APPOINTMENTS(state, isFindingAppointments) {
        state.isFindingAppointments = isFindingAppointments;
    },
    SET_IS_CONFIRMING_APPOINTMENT(state, isConfirmingAppointment) {
        state.isConfirmingAppointment = isConfirmingAppointment;
    },
    UPDATE_APPOINTMENT_PAYLOAD(state, payload) {
        if (payload.userContact) {
            Object.entries(payload.userContact).forEach(([key, value]) => {
                Vue.set(state.payload.userContact, key, value);
                touch(state.validator, `userContact.${key}`);
            });
        }
        Vue.set(state.payload, 'selectedTimeSlot', payload.selectedTimeSlot);
        if (payload.selectedTimeSlot) {
            touch(state.validator, `selectedTimeSlot`);
        }
        else {
            untouch(state.validator, `selectedTimeSlot`);
        }
        validate(state.validator, state.payload, backofficeAppointmentConstraints);
    },
    VALIDATE_AND_REVEAL_PAYLOAD(state) {
        validate(state.validator, state.payload, backofficeAppointmentConstraints);
        revealViolations(state.validator);
    },
};
