import * as Sentry from '@sentry/browser';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
import { idFromIri } from '@shared/utils/iri';
export const state = () => ({
    departments: [],
    initialized: false,
});
export const getters = {
    departmentsChoices(state) {
        return state.departments.map((department) => {
            const num = idFromIri(department.id).toString().padStart(2, '0');
            return {
                value: department.id,
                label: `${num} - ${department.name}`,
            };
        });
    },
};
export const actions = {
    async loadDepartments({ commit, state }, force) {
        if (state.initialized && !force)
            return;
        try {
            const result = await fetchResourceCollection(ApiResourceName.Departments, this.$axios);
            commit('SET_DEPARTMENTS', result.data);
            commit('SET_INITIALIZED', true);
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_DEPARTMENTS', []);
            commit('SET_INITIALIZED', false);
        }
    },
};
export const mutations = {
    SET_INITIALIZED(state, initialized) {
        state.initialized = initialized;
    },
    SET_DEPARTMENTS(state, departments) {
        state.departments = [...departments];
    },
};
