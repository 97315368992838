import { DEFAULT_PAGES_SECURITY, validateRole } from '@backoffice/types/security';
export default (function ({ $auth, $config }, inject) {
    function hasAccessLevel(role) {
        var _a;
        const roles = (_a = $auth.user) === null || _a === void 0 ? void 0 : _a.roles;
        return Array.isArray(roles) && validateRole(roles, role);
    }
    function hasAccessToRoute(routeName) {
        var _a, _b, _c, _d;
        const pages = (_c = (_b = (_a = $config.insurerConfig.backoffice) === null || _a === void 0 ? void 0 : _a.security) === null || _b === void 0 ? void 0 : _b.pages) !== null && _c !== void 0 ? _c : {};
        const roles = [(_d = pages[routeName]) !== null && _d !== void 0 ? _d : DEFAULT_PAGES_SECURITY[routeName]].flat();
        if (!roles.length)
            return true;
        if (!$auth.loggedIn)
            return false;
        return roles.some((role) => hasAccessLevel(role));
    }
    inject('hasAccessLevel', hasAccessLevel);
    inject('hasAccessToRoute', hasAccessToRoute);
});
