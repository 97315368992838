export function moveElementByIndex(array, from, to) {
    const [elem] = array.splice(from, 1);
    array.splice(to, 0, elem);
    return array;
}
export function moveElement(array, fromPredicate, toPredicate, thisArg) {
    const fromIndex = array.findIndex(fromPredicate, thisArg);
    const toIndex = array.findIndex(toPredicate, thisArg);
    if (fromIndex < 0 || toIndex < 0)
        return array;
    return moveElementByIndex(array, fromIndex, toIndex);
}
