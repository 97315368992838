import * as Sentry from '@sentry/browser';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
import { idFromIri } from '@shared/utils/iri';
export const state = () => ({
    productLines: [],
    initialized: false,
});
export const getters = {
    productLinesChoices(state) {
        return state.productLines.map((productLine) => {
            return {
                value: idFromIri(productLine.id),
                label: productLine.label,
            };
        });
    },
};
export const actions = {
    async loadProductLines({ commit, state }, force) {
        if (state.initialized && !force)
            return;
        try {
            const result = await fetchResourceCollection(ApiResourceName.ProductLines, this.$axios);
            commit('SET_PRODUCT_LINES', result.data);
            commit('SET_INITIALIZED', true);
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_PRODUCT_LINES', []);
            commit('SET_INITIALIZED', false);
        }
    },
};
export const mutations = {
    SET_INITIALIZED(state, initialized) {
        state.initialized = initialized;
    },
    SET_PRODUCT_LINES(state, productLines) {
        state.productLines = [...productLines];
    },
};
