export const DAMAGE_VALUATIONS_PER_PAGE = 30;
export const DAMAGES_VALUATIONS_FILTERS_MAPPING = {
    from: 'createdAt[after]',
    to: 'createdAt[before]',
    title: 'title',
    firstName: 'creator.firstname',
    lastName: 'creator.lastname',
};
export const DAMAGES_VALUATIONS_SORTING_MAPPING = {
    createdAt: 'order[createdAt]',
    creator: 'order[creator.lastname]',
    title: 'order[title]',
};
