import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, min_value as minValue, max_value as maxValue, } from 'vee-validate/dist/rules';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
const veeValidatePlugin = (ctx) => {
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    extend('required', {
        ...required,
        message: 'Veuillez renseigner une valeur',
    });
    extend('email', {
        ...email,
        message: 'Veuillez renseigner une adresse email valide',
    });
    extend('min_value', {
        ...minValue,
        message: "Veuillez renseigner une valeur d'un minimum de {min}",
    });
    extend('max_value', {
        ...maxValue,
        message: "Veuillez renseigner une valeur d'un maximum de {max}",
    });
    extend('zipcode', {
        validate: (value) => {
            if (typeof value !== 'string')
                return false;
            return /[0-9]{5}/.test(value);
        },
        message: 'Veuillez renseigner un code postal valide',
    });
    extend('unique_email', {
        validate: async (email) => {
            const { total } = await fetchResourceCollection(ApiResourceName.Users, ctx.$axios, { email });
            return total === 0;
        },
        message: 'Cette adresse email est déjà utilisée',
    });
};
export default veeValidatePlugin;
