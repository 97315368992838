import * as Sentry from '@sentry/browser';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { HOUSING_TYPES_TRAD_KEYS } from '@shared/contract/labels';
import { ApiResourceName } from '@shared/types/api/api';
import { uuidFromIri } from '@shared/utils/iri';
export const state = () => ({
    housingTypes: [],
    initialized: false,
});
export const getters = {
    housingTypesChoices(state) {
        return state.housingTypes.map((housingType) => ({
            value: uuidFromIri(housingType.id),
            tradKey: HOUSING_TYPES_TRAD_KEYS[housingType.slug],
        }));
    },
};
export const actions = {
    async loadHousingTypes({ commit, state }, force) {
        if (state.initialized && !force)
            return;
        try {
            const result = await fetchResourceCollection(ApiResourceName.HousingTypes, this.$axios);
            commit('SET_HOUSING_TYPES', result.data);
            commit('SET_INITIALIZED', true);
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_HOUSING_TYPES', []);
            commit('SET_INITIALIZED', false);
        }
    },
};
export const mutations = {
    SET_INITIALIZED(state, initialized) {
        state.initialized = initialized;
    },
    SET_HOUSING_TYPES(state, housingTypes) {
        state.housingTypes = [...housingTypes];
    },
};
