import * as Sentry from '@sentry/browser';
import { SortDirection } from '@backoffice/types/sort';
import { CLAIMS_FILTERS_MAPPING, CLAIMS_PER_PAGE, CLAIMS_SORTING_MAPPING, } from '@backoffice/types/store';
import { fetchResourceCollection, newListFilters, newListSorting } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
export const name = 'claims';
export const namespaced = true;
export const state = () => ({
    data: [],
    total: 0,
    loading: false,
    page: 1,
    filters: newListFilters({ from: undefined, to: undefined }),
    sorting: newListSorting('createdAt', SortDirection.Desc),
    itemsPerPage: CLAIMS_PER_PAGE,
    filterTotal: 0,
});
export const getters = {
    totalPages(state) {
        return Math.ceil((state.total || 0) / state.itemsPerPage);
    },
    totalFilteredClaims: (state) => {
        return state.filterTotal;
    },
};
export const actions = {
    async loadClaims({ commit, state }) {
        commit('SET_LOADING', true);
        try {
            const result = await fetchResourceCollection(ApiResourceName.Claims, this.$axios, state.page, [state.filters, CLAIMS_FILTERS_MAPPING], [state.sorting, CLAIMS_SORTING_MAPPING], { 'exists[type]': true, 'exists[contract]': true, itemsPerPage: state.itemsPerPage });
            commit('SET_CLAIMS', result.data);
            if (Object.values(state.filters).some((val) => typeof val !== 'undefined')) {
                commit('SET_TOTAL_FILTER', result.total);
            }
            else {
                commit('SET_TOTAL', result.total);
            }
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_CLAIMS', []);
            commit('SET_TOTAL', 0);
        }
        finally {
            commit('SET_LOADING', false);
        }
    },
    async changePage({ commit, dispatch }, page) {
        commit('SET_PAGE', page);
        await dispatch('loadClaims');
    },
    async filter({ commit, dispatch }, filters) {
        commit('SET_PAGE', 1);
        commit('SET_FILTERS', filters);
        await dispatch('loadClaims');
    },
    async sort({ commit, dispatch }, sorting) {
        commit('SET_SORTING', sorting);
        await dispatch('loadClaims');
    },
    async changeItemsPerPage({ commit, dispatch }, itemsPerPage) {
        commit('SET_ITEMS_PER_PAGE', itemsPerPage);
        await dispatch('loadClaims');
    },
};
export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_CLAIMS(state, claims) {
        state.data.splice(0, state.data.length, ...claims);
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_TOTAL_FILTER(state, total) {
        state.filterTotal = total;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_FILTERS(state, filters) {
        for (const key in filters)
            state.filters[key] = filters[key];
    },
    SET_SORTING(state, sorting) {
        state.sorting.by = sorting.by;
        state.sorting.direction = sorting.direction;
    },
    RESET_FILTERS(state) {
        for (const key in state.filters)
            state.filters[key] = undefined;
    },
    RESET_SORTING(state) {
        state.sorting.by = 'createdAt';
        state.sorting.direction = SortDirection.Desc;
    },
    SET_ITEMS_PER_PAGE(state, itemsPerPage) {
        state.itemsPerPage = itemsPerPage;
    },
};
