export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":{"de":["en","fr"],"it":["en","fr"],"en":["fr"]},"silentFallbackWarn":true,"silentTranslationWarn":true},
  vueI18nLoader: true,
  locales: [{"name":"Deutsch","code":"de","iso":"de-DE","file":"de.ts"},{"name":"English","code":"en","iso":"en-US","file":"en.ts"},{"name":"Francais","code":"fr","iso":"fr-FR","file":"fr.ts"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it.ts"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "i18n/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Deutsch","code":"de","iso":"de-DE","file":"de.ts"},{"name":"English","code":"en","iso":"en-US","file":"en.ts"},{"name":"Francais","code":"fr","iso":"fr-FR","file":"fr.ts"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it.ts"}],
  localeCodes: ["de","en","fr","it"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "e",
  4: ".",
  5: "t",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: ".",
  14: ".",
  15: "/",
  16: ".",
  17: ".",
  18: "/",
  19: "s",
  20: "r",
  21: "c",
  22: "/",
  23: "b",
  24: "a",
  25: "c",
  26: "k",
  27: "o",
  28: "f",
  29: "f",
  30: "i",
  31: "c",
  32: "e",
  33: "/",
  34: "i",
  35: "1",
  36: "8",
  37: "n",
  38: "/",
  39: "d",
  40: "e",
  41: ".",
  42: "t",
  43: "s",
  44: "\"",
  45: ",",
  46: "\"",
  47: "e",
  48: "n",
  49: ".",
  50: "t",
  51: "s",
  52: "\"",
  53: ":",
  54: "\"",
  55: ".",
  56: ".",
  57: "/",
  58: ".",
  59: ".",
  60: "/",
  61: ".",
  62: ".",
  63: "/",
  64: "s",
  65: "r",
  66: "c",
  67: "/",
  68: "b",
  69: "a",
  70: "c",
  71: "k",
  72: "o",
  73: "f",
  74: "f",
  75: "i",
  76: "c",
  77: "e",
  78: "/",
  79: "i",
  80: "1",
  81: "8",
  82: "n",
  83: "/",
  84: "e",
  85: "n",
  86: ".",
  87: "t",
  88: "s",
  89: "\"",
  90: ",",
  91: "\"",
  92: "f",
  93: "r",
  94: ".",
  95: "t",
  96: "s",
  97: "\"",
  98: ":",
  99: "\"",
  100: ".",
  101: ".",
  102: "/",
  103: ".",
  104: ".",
  105: "/",
  106: ".",
  107: ".",
  108: "/",
  109: "s",
  110: "r",
  111: "c",
  112: "/",
  113: "b",
  114: "a",
  115: "c",
  116: "k",
  117: "o",
  118: "f",
  119: "f",
  120: "i",
  121: "c",
  122: "e",
  123: "/",
  124: "i",
  125: "1",
  126: "8",
  127: "n",
  128: "/",
  129: "f",
  130: "r",
  131: ".",
  132: "t",
  133: "s",
  134: "\"",
  135: ",",
  136: "\"",
  137: "i",
  138: "t",
  139: ".",
  140: "t",
  141: "s",
  142: "\"",
  143: ":",
  144: "\"",
  145: ".",
  146: ".",
  147: "/",
  148: ".",
  149: ".",
  150: "/",
  151: ".",
  152: ".",
  153: "/",
  154: "s",
  155: "r",
  156: "c",
  157: "/",
  158: "b",
  159: "a",
  160: "c",
  161: "k",
  162: "o",
  163: "f",
  164: "f",
  165: "i",
  166: "c",
  167: "e",
  168: "/",
  169: "i",
  170: "1",
  171: "8",
  172: "n",
  173: "/",
  174: "i",
  175: "t",
  176: ".",
  177: "t",
  178: "s",
  179: "\"",
  180: "}",
}

export const localeMessages = {
  'de.ts': () => import('../../../../src/backoffice/i18n/de.ts' /* webpackChunkName: "lang-de.ts" */),
  'en.ts': () => import('../../../../src/backoffice/i18n/en.ts' /* webpackChunkName: "lang-en.ts" */),
  'fr.ts': () => import('../../../../src/backoffice/i18n/fr.ts' /* webpackChunkName: "lang-fr.ts" */),
  'it.ts': () => import('../../../../src/backoffice/i18n/it.ts' /* webpackChunkName: "lang-it.ts" */),
}
