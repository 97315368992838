import { CONFIG } from '@shared/environment/environment';
export default (function ({ $axios }) {
    $axios.setBaseURL(CONFIG.apiUrl);
    $axios.onRequest((config) => {
        var _a;
        if ((_a = config.url) === null || _a === void 0 ? void 0 : _a.startsWith('/')) {
            config.headers['Claims-Frontend'] = 'backoffice';
        }
        return config;
    });
});
