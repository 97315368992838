import { VuexPersistence } from 'vuex-persist';
const persistedMutations = ['settings/SET_MENU_ENTRIES'];
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        settings: { menuEntries: state.settings.menuEntries },
    }),
    filter: (mutation) => persistedMutations.includes(mutation.type),
});
export const plugins = [vuexLocal.plugin];
