import Vue from 'vue';
import { entityChangeByChangeKey } from '@backoffice/claim/edition/entitiesChanges';
import { isCreateChangeKey } from '@shared/claim/entities';
import { removeArrayItem } from '@shared/utils/removeArrayItem';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, revealViolations, touch, validate, } from '@shared/validator/validator';
export function mergeUpdatedEntity(initial, changes) {
    return { ...initial, ...(changes !== null && changes !== void 0 ? changes : {}) };
}
export function computeEntitiesAndFields({ modelFieldsContext, modelToFields, validationConstraintsContext, existingEntities, changes, }) {
    const editionModelFieldsContext = { ...modelFieldsContext, edition: true };
    // Do not return deleted entities
    existingEntities = existingEntities.filter(({ changeKey }) => typeof changeKey === 'string' && !changes.delete.includes(changeKey));
    const existingEntitiesWithFields = existingEntities.map(({ changeKey, entity }) => {
        var _a;
        const modelFields = modelToFields(entity, modelFieldsContext);
        const change = changes.update[changeKey !== null && changeKey !== void 0 ? changeKey : 0];
        const changed = mergeUpdatedEntity(entity, change === null || change === void 0 ? void 0 : change.data);
        const changedModelFields = modelToFields(changed, editionModelFieldsContext);
        let validation;
        // Take validator from existing change
        let validator = change === null || change === void 0 ? void 0 : change.validator;
        // If there was no previous change, we create and validate a new validator
        if (!validator) {
            validator = newValidator(changed);
            validation = new ValidationContext(validator);
            if ((_a = modelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints) {
                validation.validate(changed, modelFields.editable.constraints(changed, validationConstraintsContext));
            }
        }
        else {
            validation = new ValidationContext(validator);
        }
        return {
            changeKey,
            drafted: entity,
            modelFields,
            changed: changed,
            changedModelFields,
            validation,
        };
    });
    const createdEntitiesWithFields = changes.create.map(({ validator, data: changed }, changeKey) => {
        var _a, _b;
        // Get drafted from existing entities
        const drafted = (_b = (_a = existingEntities.find(({ changeKey: existingChangeKey }) => changeKey === existingChangeKey)) === null || _a === void 0 ? void 0 : _a.entity) !== null && _b !== void 0 ? _b : { ...changed };
        const modelFields = modelToFields(drafted, modelFieldsContext);
        const changedModelFields = modelToFields(changed, editionModelFieldsContext);
        return {
            changeKey,
            drafted: drafted,
            modelFields,
            changed: changed,
            changedModelFields,
            validation: new ValidationContext(validator),
        };
    });
    return [...existingEntitiesWithFields, ...createdEntitiesWithFields];
}
export function undoEntityChange({ changeKey, entityChanges, }) {
    const change = entityChangeByChangeKey(entityChanges, changeKey);
    if (!change) {
        // No op to undo unexisting change
        return;
    }
    if (!change.snapshot) {
        // Change has no snapshot to undo, we remove it
        if (isCreateChangeKey(changeKey)) {
            const index = changeKey !== null && changeKey !== void 0 ? changeKey : 0;
            Vue.set(entityChanges, 'create', removeArrayItem(entityChanges.create, index));
            return;
        }
        Vue.delete(entityChanges.update, changeKey);
        return;
    }
    // Change has snapshot, we replace data with snapshot
    Vue.set(change, 'data', { ...change.snapshot });
    Vue.delete(change, 'snapshot');
    // TODO validate change to keep validator in sync with model
}
export function touchAndValidateEntity({ changeKey, modelKey, entityChanges, constraints, entity, }) {
    const change = entityChangeByChangeKey(entityChanges, changeKey);
    if (!change) {
        throw new Error('Cannot find change for change key');
    }
    const data = entity !== null && entity !== void 0 ? entity : change.data;
    if (Array.isArray(modelKey)) {
        for (const key of modelKey) {
            touch(change.validator, key);
        }
    }
    else {
        touch(change.validator, modelKey);
    }
    validate(change.validator, data, constraints);
    return change.validator;
}
export function validateAndRevealEntity({ changeKey, entityChanges, constraints, entity, }) {
    const change = entityChangeByChangeKey(entityChanges, changeKey);
    if (!change) {
        throw new Error('Cannot find change for change key');
    }
    const data = entity !== null && entity !== void 0 ? entity : change.data;
    validate(change.validator, data, constraints);
    revealViolations(change.validator);
}
