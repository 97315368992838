import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
export const name = 'newClaim';
export const namespaced = true;
export const state = () => {
    return {
        userSearchQuery: '',
        selectedUser: null,
        selectedContract: null,
        isSearching: false,
        isCreating: false,
        contracts: [],
        searchError: null,
        prevPage: null,
        nextPage: null,
    };
};
export const getters = {};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    setUserQuery({ commit }, query) {
        commit('SET_USER_QUERY', query);
    },
    setSelectedUser({ commit }, selectedUser) {
        commit('SET_SELECTED_USER', selectedUser);
    },
    setSelectedContract({ commit }, selectedContract) {
        commit('SET_SELECTED_CONTRACT', selectedContract);
    },
    async createContract({ commit }, contract) {
        try {
            commit('SET_IS_CREATING', true);
            const data = await this.$axios.$post(apiResourcePath(ApiResourceName.ContractsPrime), contract);
            commit('SET_SELECTED_USER', await this.$axios.$get(apiResourcePath(ApiResourceName.Users) + `/${data.result.user.id}`));
            commit('SET_SELECTED_CONTRACT', await this.$axios.$get(apiResourcePath(ApiResourceName.Contracts) + `/${data.result.contract.id}`));
            commit('SET_IS_CREATING', false);
        }
        catch (err) {
            let message;
            if (err instanceof Error)
                message = err.message;
            else
                message = Object.prototype.toString.call(err);
            commit('SET_CONTRACTS_FIND_FAILED', message);
        }
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_USER_QUERY(state, query) {
        state.userSearchQuery = query;
    },
    SET_SELECTED_USER(state, selectedUser) {
        state.selectedUser = selectedUser;
    },
    SET_SELECTED_CONTRACT(state, selectedContract) {
        state.selectedContract = selectedContract;
    },
    SET_IS_CREATING(state, isCreating) {
        state.isCreating = isCreating;
    },
    SET_CONTRACTS_FIND_FAILED(state, error) {
        state.isSearching = false;
        state.isCreating = false;
        state.searchError = error;
    },
};
