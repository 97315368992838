import * as Sentry from '@sentry/browser';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
export const state = () => ({
    claimTypes: [],
    initialized: false,
});
export const getters = {
    availableClaimTypes(state) {
        return state.claimTypes.filter((claimType) => !claimType.hidden);
    },
};
export const actions = {
    async loadClaimTypes({ commit, state }, force) {
        if (state.initialized && !force)
            return;
        try {
            const result = await fetchResourceCollection(ApiResourceName.ClaimTypes, this.$axios);
            commit('SET_CLAIM_TYPES', result.data);
            commit('SET_INITIALIZED', true);
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_CLAIM_TYPES', []);
            commit('SET_INITIALIZED', false);
        }
    },
};
export const mutations = {
    SET_INITIALIZED(state, initialized) {
        state.initialized = initialized;
    },
    SET_CLAIM_TYPES(state, claimTypes) {
        state.claimTypes = [...claimTypes];
    },
};
