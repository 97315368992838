import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { coveringCategoryLabelsFromInfo } from '@shared/roomDamage/constants';
import { EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE } from '@shared/roomDamage/surfaceDamage';
import { ClaimTypeTrigram, FireBeginLocation } from '@shared/types/api/claim';
import { ExternalDamageSlugWithProtectionNeeded } from '@shared/types/api/externalDamage';
export function claimModelToFieldsContext({ info, contract, contractData, settings, user, }, translate, edition = false) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return {
        edition,
        translate,
        projection: ModelFieldsProjection.Backoffice,
        type: (_b = (_a = info.claim.type) === null || _a === void 0 ? void 0 : _a.trigram) !== null && _b !== void 0 ? _b : ClaimTypeTrigram.Empty,
        categories: (_c = info.personalPropertyCategoriesByIri) !== null && _c !== void 0 ? _c : {},
        coveringCategoriesBySurfaceType: (_d = info.coveringCategoriesBySurfaceType) !== null && _d !== void 0 ? _d : EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE,
        coveringCategoryLabels: coveringCategoryLabelsFromInfo(info),
        housingType: (_e = contract === null || contract === void 0 ? void 0 : contract.housingType.slug) !== null && _e !== void 0 ? _e : null,
        damageDate: info.claim.damageDate,
        stormSnowHailDamageCauseType: (_g = (_f = info.claim.stormSnowHailDamageQualification) === null || _f === void 0 ? void 0 : _f.causeType) !== null && _g !== void 0 ? _g : null,
        contractData: contractData !== null && contractData !== void 0 ? contractData : {
            protectionMeans: [],
            protectionMeasures: { question: '', information: '', measures: [] },
        },
        otherGuaranteeType: info.claim.otherGuaranteeType,
        vehicle: ((_j = (_h = info.claim) === null || _h === void 0 ? void 0 : _h.fireDamageQualification) === null || _j === void 0 ? void 0 : _j.fireBegin) === FireBeginLocation.Vehicle &&
            ((_l = (_k = info.claim) === null || _k === void 0 ? void 0 : _k.fireDamageQualification) === null || _l === void 0 ? void 0 : _l.hasThirdPartyInfo) === true,
        irsiConvention: (_m = info.claim.irsiConvention) !== null && _m !== void 0 ? _m : {
            isApplicable: null,
            isResponsible: null,
            bracket: null,
        },
        isProtectionMeasureNeeded: info.claim.counting.externalDamages.some((externalDamage) => {
            return ExternalDamageSlugWithProtectionNeeded.includes(externalDamage.slug);
        }),
        settings,
        availableRoomOptions: info.availableRoomOptions,
        availableSurfaceOptions: info.availableSurfaceOptions,
        user,
    };
}
