import { ContractHousingCapacityCode, ContractStatus, HousingTypeSlug, CoverCode, ContractProblem, } from '@shared/types/api/contract';
export const CONTRACT_STATUSES_TRAD_KEYS = {
    [ContractStatus.Cancelled]: 'contract.status.cancelled',
    [ContractStatus.Suspended]: 'contract.status.suspended',
    [ContractStatus.InEffect]: 'contract.status.in_effect',
};
export const CONTRACT_PROBLEMS_TRAD_KEYS = {
    [ContractProblem.Unpaid]: 'contract.problem.unpaid',
    [ContractProblem.ManagementIncident]: 'contract.problem.management_incident',
    [ContractProblem.automaticCompensation24]: 'contract.problem.automatic_compensation_24',
};
export const HOUSING_TYPES_TRAD_KEYS = {
    [HousingTypeSlug.Apartment]: 'contract.housing_type.apartment',
    [HousingTypeSlug.House]: 'contract.housing_type.house',
    [HousingTypeSlug.Block]: 'contract.housing_type.block',
    [HousingTypeSlug.IndustrialLocal]: 'contract.housing_type.industrial_local',
    [HousingTypeSlug.ProfessionalLocal]: 'contract.housing_type.professional_local',
    [HousingTypeSlug.Garage]: 'contract.housing_type.garage',
    [HousingTypeSlug.CommercialLocal]: 'contract.housing_type.commercial_local',
    [HousingTypeSlug.Parking]: 'contract.housing_type.parking',
    [HousingTypeSlug.Other]: 'contract.housing_type.other',
    [HousingTypeSlug.School]: 'contract.housing_type.school',
    [HousingTypeSlug.MunicipalPool]: 'contract.housing_type.municipal_pool',
    [HousingTypeSlug.MedicalOffice]: 'contract.housing_type.medical_office',
    [HousingTypeSlug.Hotel]: 'contract.housing_type.hotel',
    [HousingTypeSlug.Farm]: 'contract.housing_type.farm',
    [HousingTypeSlug.MobileResidence]: 'contract.housing_type.mobile_residence',
};
export const CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS = {
    [ContractHousingCapacityCode.CoOwnerOccupier]: 'contract.housing_capacity.co_owner_occupier',
    [ContractHousingCapacityCode.CoOwnerNonOccupier]: 'contract.housing_capacity.co_owner_non_occupier',
    [ContractHousingCapacityCode.OwnerOccupier]: 'contract.housing_capacity.owner_occupier',
    [ContractHousingCapacityCode.Usufruct]: 'contract.housing_capacity.usufruct',
    [ContractHousingCapacityCode.OwnerNonOccupier]: 'contract.housing_capacity.owner_non_occupier',
    [ContractHousingCapacityCode.WorkAccomodation]: 'contract.housing_capacity.work_accomodation',
    [ContractHousingCapacityCode.FreeOfChargeOccupier]: 'contract.housing_capacity.free_of_charge_occupier',
    [ContractHousingCapacityCode.BareOwnership]: 'contract.housing_capacity.bare_ownership',
    [ContractHousingCapacityCode.NeighbourOwner]: 'contract.housing_capacity.neighbour_owner',
    [ContractHousingCapacityCode.Other]: 'contract.housing_capacity.other',
    [ContractHousingCapacityCode.CoOwnership]: 'contract.housing_capacity.co_ownership',
    [ContractHousingCapacityCode.Tenant]: 'contract.housing_capacity.tenant',
    [ContractHousingCapacityCode.VehicleOwner]: 'contract.housing_capacity.vehicle_owner',
    [ContractHousingCapacityCode.RentalBuildingOwner]: 'contract.housing_capacity.rental_building_owner',
    [ContractHousingCapacityCode.CoOwnerEmptyFlat]: 'contract.housing_capacity.co_owner_empty_flat',
    [ContractHousingCapacityCode.UniqueTenant]: 'contract.housing_capacity.unique_tenant',
    [ContractHousingCapacityCode.OwnerManager]: 'contract.housing_capacity.owner_manager',
    [ContractHousingCapacityCode.TenantManager]: 'contract.housing_capacity.tenant_manager',
    [ContractHousingCapacityCode.Manager]: 'contract.housing_capacity.manager',
    [ContractHousingCapacityCode.Owner]: 'contract.housing_capacity.owner',
    [ContractHousingCapacityCode.FreeSyndicate]: 'contract.housing_capacity.free_syndicate',
    [ContractHousingCapacityCode.TenantWithInsuranceForOwnerNonOccupier]: 'contract.housing_capacity.tenant_with_insurance_for_owner_non_occupier',
    [ContractHousingCapacityCode.SubTenant]: 'contract.housing_capacity.sub_tenant',
    [ContractHousingCapacityCode.HousedInMilitaryCompound]: 'contract.housing_capacity.housed_in_military_compound',
    [ContractHousingCapacityCode.NecessityServiceOccupier]: 'contract.housing_capacity.necessity_service_occupier',
};
export const CONTRACT_COVERS_TRAD_KEYS = {
    [CoverCode.Food]: 'contract.covers.food',
    [CoverCode.Micro]: 'contract.covers.micro',
    [CoverCode.SwimmingPool]: 'contract.covers.swimming_pool',
    [CoverCode.ValueAsNew]: 'contract.covers.van',
    [CoverCode.NoDeductableAmount]: 'contract.covers.no_deductable_amount',
    [CoverCode.RobberyRoomDamagesDomicile]: 'contract.covers.robbery_room_damages_domicile',
    [CoverCode.RobberyPersonalPropertyDamagesDomicile]: 'contract.covers.robbery_personal_property_damages_domicile',
    [CoverCode.RobberyValuables]: 'contract.covers.robbery_valuables',
    [CoverCode.PersonalRobbery]: 'contract.covers.personal_robbery',
    [CoverCode.RobberyRoomDamagesHabitationLogiplus]: 'contract.covers.robbery_room_damages_habitation_logiplus',
    [CoverCode.RobberyPersonalPropertyDamagesHabitationLogiplus]: 'contract.covers.robbery_personal_property_damages_habitation_logiplus',
};
export function contractXmlLabelTradKey(xmlLabel) {
    return `contract.xml_label.${xmlLabel}`;
}
