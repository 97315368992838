import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { EntityChangeAction, isCreateChangeKey } from '@shared/claim/entities';
import { mergePayload } from '@shared/utils/mergePayload';
import { removeArrayItem } from '@shared/utils/removeArrayItem';
import { newValidator } from '@shared/validator/validator';
export function applyEntityChange(entityChanges, changeInfo) {
    var _a, _b, _c;
    if (changeInfo.action === EntityChangeAction.Create) {
        const index = (_a = changeInfo.changeKey) !== null && _a !== void 0 ? _a : 0;
        const validator = newValidator(changeInfo.payload);
        Vue.set(entityChanges.create, index, {
            data: changeInfo.payload,
            validator,
        });
        return;
    }
    if (changeInfo.action === EntityChangeAction.Delete) {
        // Remove change key from created and updated changeset
        if (isCreateChangeKey(changeInfo.changeKey)) {
            const index = (_b = changeInfo.changeKey) !== null && _b !== void 0 ? _b : 0;
            Vue.set(entityChanges, 'create', removeArrayItem(entityChanges.create, index));
            return;
        }
        if (changeInfo.changeKey in entityChanges.update) {
            Vue.delete(entityChanges.update, changeInfo.changeKey);
        }
        Vue.set(entityChanges.delete, entityChanges.delete.length, changeInfo.changeKey);
        return;
    }
    // Update change
    if (isCreateChangeKey(changeInfo.changeKey)) {
        // Updating an entity that will be created
        const index = (_c = changeInfo.changeKey) !== null && _c !== void 0 ? _c : 0;
        const existing = entityChanges.create[index];
        if (!existing) {
            throw new Error('Cannot update unexisting entity');
        }
        const data = mergePayload(existing.data, changeInfo.payload);
        if (!existing.snapshot) {
            Vue.set(existing, 'snapshot', cloneDeep({ ...existing.data }));
        }
        Vue.set(existing, 'data', data);
        return;
    }
    // Updating an existing entity
    const existing = entityChanges.update[changeInfo.changeKey];
    if (!existing) {
        Vue.set(entityChanges.update, changeInfo.changeKey, {
            data: changeInfo.payload,
            validator: newValidator(changeInfo.payload),
            snapshot: {},
        });
        return;
    }
    if (!existing.snapshot) {
        Vue.set(existing, 'snapshot', cloneDeep({ ...existing.data }));
    }
    const data = mergePayload(existing.data, changeInfo.payload);
    Vue.set(existing, 'data', data);
}
