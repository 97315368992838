import * as Sentry from '@sentry/browser';
import { moveElementByIndex } from '@shared/utils/arrays';
export const name = 'settings';
export const namespaced = true;
export const state = () => ({
    settings: null,
    menuEntries: [],
});
export const getters = {
    get(state) {
        return (name) => {
            if (state.settings === null)
                throw new Error('Settings not loaded');
            return state.settings.filter((appSetting) => appSetting.name === name)[0];
        };
    },
};
export const actions = {
    async loadSettings({ commit, state }) {
        if (!state.settings) {
            try {
                const data = await this.$axios.$get('/settings-frontend');
                commit('SET_SETTINGS', data.settings);
            }
            catch (err) {
                Sentry.captureException(err);
                commit('SET_SETTINGS', null);
            }
        }
    },
    async fetchMenuEntries({ commit }) {
        const entries = await this.$axios.$get('/backoffice/menu_entries');
        commit('SET_MENU_ENTRIES', entries);
    },
    async addMenuEntry({ commit, state }, payload) {
        const entry = await this.$axios.$post('/backoffice/menu_entries', payload);
        const entries = state.menuEntries.concat([entry]);
        commit('SET_MENU_ENTRIES', entries);
    },
    async updateMenuEntry({ commit, state }, { id, ...payload }) {
        const entry = await this.$axios.$put('/backoffice/menu_entries/' + id, payload);
        const entries = state.menuEntries.map((e) => (e.id === entry.id ? entry : e));
        commit('SET_MENU_ENTRIES', entries);
    },
    async updateMenuEntries({ commit, state }, payload) {
        const newEntries = await this.$axios.$patch('/backoffice/menu_entries', payload);
        const entries = state.menuEntries.map((entry) => { var _a; return (_a = newEntries.find((e) => e.id === entry.id)) !== null && _a !== void 0 ? _a : entry; });
        commit('SET_MENU_ENTRIES', entries);
    },
    async sortMenuEntries({ dispatch, state }, { from, to }) {
        const newEntries = moveElementByIndex([...state.menuEntries], from, to).flatMap((entry, index) => (entry.index !== index ? [{ id: entry.id, index }] : []));
        await dispatch('updateMenuEntries', newEntries);
    },
    async deleteMenuEntry({ commit, state }, id) {
        await this.$axios.$delete('/backoffice/menu_entries/' + id);
        const entries = state.menuEntries.filter((e) => e.id !== id);
        commit('SET_MENU_ENTRIES', entries);
    },
};
export const mutations = {
    SET_SETTINGS(state, settings) {
        state.settings = settings !== null && settings !== void 0 ? settings : null;
    },
    SET_MENU_ENTRIES(state, entries) {
        state.menuEntries = [...entries].sort((a, b) => a.index - b.index);
    },
};
