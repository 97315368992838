import * as Sentry from '@sentry/browser';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
import { uuidFromIri } from '@shared/utils/iri';
export const state = () => ({
    insuranceContracts: [],
    initialized: false,
});
export const getters = {
    insuranceContractsChoices(state) {
        return state.insuranceContracts.map((insuranceContract) => ({
            value: uuidFromIri(insuranceContract.id),
            label: insuranceContract.name,
        }));
    },
};
export const actions = {
    async loadInsuranceContracts({ commit, state }, force) {
        if (state.initialized && !force)
            return;
        try {
            const result = await fetchResourceCollection(ApiResourceName.InsuranceContracts, this.$axios);
            commit('SET_INSURANCE_CONTRACTS', result.data);
            commit('SET_INITIALIZED', true);
        }
        catch (err) {
            Sentry.captureException(err);
            commit('SET_INSURANCE_CONTRACTS', []);
            commit('SET_INITIALIZED', false);
        }
    },
};
export const mutations = {
    SET_INITIALIZED(state, initialized) {
        state.initialized = initialized;
    },
    SET_INSURANCE_CONTRACTS(state, insuranceContracts) {
        state.insuranceContracts = [...insuranceContracts];
    },
};
